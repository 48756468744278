import { render, staticRenderFns } from "./mdm-footer.vue?vue&type=template&id=f15228b2&"
import script from "./mdm-footer.vue?vue&type=script&lang=js&"
export * from "./mdm-footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MdmFooterPolitic: require('D:/distrib/MDM/components/commun/footer-items/mdm-footer-politic.vue').default,MdmFooterTop: require('D:/distrib/MDM/components/commun/footer-items/mdm-footer-top.vue').default})
