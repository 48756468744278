
import MixLocales from "@/mixins/MixLocales.js";
import mdmFooterSocial from "./mdm-footer-social.vue";
export default {
  components: { mdmFooterSocial },
  name: "mdm-footer-item-3",
  mixins: [MixLocales],
  computed: {
    joindres() {
      return this.$store.state.footer.joindres;
    },
  },
};
