import { render, staticRenderFns } from "./mdm-loading.vue?vue&type=template&id=aca2913a&"
import script from "./mdm-loading.vue?vue&type=script&lang=js&"
export * from "./mdm-loading.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MdmLogo: require('D:/distrib/MDM/components/commun/mdm-logo.vue').default})
