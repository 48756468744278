
import MixLocales from "@/mixins/MixLocales.js";
import MdmFooterSocial from "../commun/footer-items/mdm-footer-social.vue";
import MdmContactTop from "../commun/mdm-contact-top.vue";
import MdmNavbar from "./mdm-navbar.vue";
export default {
  name: "mdm-navigation",
  mixins: [MixLocales],
  components: { MdmFooterSocial, MdmContactTop, MdmNavbar },
  data() {
    return {
      navbarOptions: {
        elementId: "main-navbar",
        isUsingVueRouter: true,
        mobileBreakpoint: 992,
        brandImagePath: "/",
        brandImage: "/icon.png",
        brandImageAltText: "MDM-Logo",
        // collapseButtonOpenColor: "#c12129",
        // collapseButtonCloseColor: "#c12129",
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: "MDM Navigation",
        tooltipAnimationType: "perspective",
        tooltipPlacement: "bottom",
        menuOptionsLeft: null,
      },
    };
  },
  computed: {
    menus() {
      return this.$store.state.menu.list;
    },
  },
  watch: {
    locale: {
      handler() {
        this.changedMenu();
      },
      deep: true,
    },
  },
  methods: {
    menuOptionsLeft() {
      return this.menus.map((item) => {
        //get menu parent
        let itemMenu = {
          type: "link",
          text: this.$t(item.title, this.locale),
        };
        //get menu children
        if (item.children) {
          itemMenu.subMenuOptions = item.children.map((child) => {
            let itemChild =
              child.type === "link"
                ? {
                    // isLinkAction: true,
                    type: "link",
                    text: this.$t(child.title, this.locale),
                    // subText: this.$t(child.title, this.locale),
                    path: { name: `${child.name}___${this.locale}` },
                  }
                : {
                    type: "hr",
                  };
            return itemChild;
          });
        } else {
          itemMenu.path = { name: `${item.name}___${this.locale}` };
        }
        return itemMenu;
      });
    },
    changedMenu() {
      this.navbarOptions.menuOptionsLeft = this.menuOptionsLeft();
    },
  },
  beforeMount() {
    this.changedMenu();
  },
};
