export const state = () => ({
  listInfo: [
    {
      profile: "/teams/amraoui.jpg",
      fname: "teams.amraoui.fname",
      lname: "teams.amraoui.lname",
      profession: "teams.amraoui.profession",
      "profession-ext": "teams.amraoui.profession-ext",
      "description-abrv": "teams.amraoui.description-abrv",
      "description-detail": "teams.amraoui.description-detail",
      inContact: true,
    },
    {
      profile: "/teams/allaoui.jpg",
      fname: "teams.allaoui.fname",
      lname: "teams.allaoui.lname",
      profession: "teams.allaoui.profession",
      "profession-ext": "teams.allaoui.profession-ext",
      "description-abrv": "teams.allaoui.description-abrv",
      "description-detail": "teams.allaoui.description-detail",
      inContact: true,
    },
    {
      profile: "/teams/touami.jpg",
      fname: "teams.touami.fname",
      lname: "teams.touami.lname",
      profession: "teams.touami.profession",
      "profession-ext": "teams.touami.profession-ext",
      "description-abrv": "teams.touami.description-abrv",
      "description-detail": "teams.touami.description-detail",
      inContact: true,
    },
    {
      profile: "/teams/himeur.jpg",
      fname: "teams.himeur.fname",
      lname: "teams.himeur.lname",
      profession: "teams.himeur.profession",
      "profession-ext": "teams.himeur.profession-ext",
      "description-abrv": "teams.himeur.description-abrv",
      "description-detail": "teams.himeur.description-detail",
      inContact: true,
    },
    {
      profile: "/teams/amjahdi.jpg",
      fname: "teams.amjahdi.fname",
      lname: "teams.amjahdi.lname",
      profession: "teams.amjahdi.profession",
      "profession-ext": "teams.amjahdi.profession-ext",
      "description-abrv": "teams.amjahdi.description-abrv",
      "description-detail": "teams.amjahdi.description-detail",
      inContact: true,
    },
    {
      profile: "/teams/benmarraze.jpg",
      fname: "teams.benmarraze.fname",
      lname: "teams.benmarraze.lname",
      profession: "teams.benmarraze.profession",
      "profession-ext": "teams.benmarraze.profession-ext",
      "description-abrv": "teams.benmarraze.description-abrv",
      "description-detail": "teams.benmarraze.description-detail",
      inContact: true,
    },
  ],
});
