
import MixLocales from "@/mixins/MixLocales.js";
// import MdmLocales from "@/components/commun/mdm-locales.vue";
// import MdmLogo from "@/components/commun/mdm-logo.vue";
// import MdmFooterSocial from "../commun/footer-items/mdm-footer-social.vue";
// import MdmContactTop from "../commun/mdm-contact-top.vue";
export default {
  name: "mdm-navbar",
  mixins: [MixLocales],
  // components: { MdmLocales, MdmLogo, MdmFooterSocial, MdmContactTop },
  // computed: {
  //   menus() {
  //     return this.$store.state.menu.list;
  //   },
  // },
};
