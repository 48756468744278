
import MixLocales from "@/mixins/MixLocales.js";
export default {
  name: "mdm-footer-item-2",
  mixins: [MixLocales],
  data() {
    return {
      list: [
        "name",
        "forme_juridique",
        "siege_social",
        "groupe_fondateur",
        "capital",
      ],
    };
  },
};
