export const state = () => ({
  socialMedias: [
    // {
    //   to: "https://www.linkedin.com",
    //   title: "linkedin",
    //   icon: "linkedin",
    // },
    // { to: "https://www.facebook.com", title: "facebook", icon: "facebook" },
    // { to: "https://www.youtube.com", title: "youtube", icon: "youtube" },
    // { to: "https://www.instagram.com", title: "instagram", icon: "instagram" },
  ],
  joindres: [
    {
      profile: "/teams/amraoui.jpg",
      fname: "teams.amraoui.fname",
      lname: "teams.amraoui.lname",
      profession: "teams.amraoui.profession",
      "profession-ext": "teams.amraoui.profession-ext",
      description: "teams.amraoui.description",
      inContact: true,
      contact: [
        {
          to: "mailto:mohammed.amraoui@mdmcaps.com",
          title: "mohammed.amraoui@mdmcaps.com",
          icon: "mailbox",
          divider: false,
        },
        {
          to: "tel:+33695005933",
          title: "(+33) 695 005 933",
          icon: "phone",
          divider: true,
        },
      ],
    },
    {
      profile: "/teams/benmarraze.jpg",
      fname: "teams.benmarraze.fname",
      lname: "teams.benmarraze.lname",
      profession: "teams.benmarraze.profession",
      "profession-ext": "teams.benmarraze.profession-ext",
      description: "teams.benmarraze.description",
      inContact: true,
      contact: [
        {
          to: "mailto:marc.benmarraze@mdmcaps.com",
          title: "marc.benmarraze@mdmcaps.com",
          icon: "mailbox",
          divider: false,
        },
        {
          to: "tel:+33698962810",
          title: "(+33) 698 962 810",
          icon: "phone",
          divider: true,
        },
      ],
    },
    // {
    //   to: "mailto:mohammed.amraoui@mdmcaps.com",
    //   title: "Mohammed AMRAOUI",
    //   icon: "mailbox",
    //   divider: false,
    // },
    // {
    //   to: "tel:+33695005933",
    //   title: "(+33) 695 005 933",
    //   icon: "phone",
    //   divider: true,
    // },
    // {
    //   to: "mailto:mohammed.amraoui@mdmcaps.com",
    //   title: "Mohammed Benmarraze Marc",
    //   icon: "mailbox",
    //   divider: false,
    // },
    // {
    //   to: "tel:+33698962810",
    //   title: "(+33) 698 962 810",
    //   icon: "phone",
    //   divider: true,
    // },
    // {
    //   to: "#",
    //   title: "MDM CAPITAL SYNERGIES SA, 4 rue de l’ameublement 75011 PARIS",
    //   icon: "pin-map",
    // },
  ],
});

export const mutations = {};

export const actions = {};
