
import MixLocales from "@/mixins/MixLocales.js";
import mdmFooterItem1 from "./footer-items/mdm-footer-item1.vue";
import mdmFooterItem2 from "./footer-items/mdm-footer-item2.vue";
import mdmFooterItem3 from "./footer-items/mdm-footer-item3.vue";
import mdmFooterItem4 from "./footer-items/mdm-footer-item4.vue";
import mdmFooterPolitic from "./footer-items/mdm-footer-politic.vue";
import mdmFooterTop from "./footer-items/mdm-footer-top.vue";
export default {
  name: "mdm-footer",
  components: {
    mdmFooterItem1,
    mdmFooterItem2,
    mdmFooterItem3,
    mdmFooterItem4,
    mdmFooterPolitic,
    mdmFooterTop,
  },
  mixins: [MixLocales],
};
