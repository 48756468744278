export const state = () => ({
  locale: "fr",
  locales: ["fr", "gb"] /* "es", "de" */,
  name: "MDM Caps",
  by: "Constructeur",
  byLink: "https://www.achmitech.com/",
  caroussels: [
    {
      type: "text",
      logo: "/icon.png",
      title: "commun.title",
      description: "strategy.strategy.description",
      image: "/imgs/image1.webp",
      link: "/about/presentation",
    },
    {
      type: "image",
      title: "commun.title",
      description: "strategy.strategy.description",
      image: "/imgs/image2.webp",
      link: "/about/presentation",
    },
    {
      type: "image",
      title: "commun.title",
      description: "strategy.strategy.description",
      image: "/imgs/image3.webp",
      link: "/about/presentation",
    },
  ],
});

export const mutations = {
  SET_LOCALES(mutate_state, payload) {
    mutate_state.locales = payload;
  },
  SET_LOCALE(mutate_state, payload) {
    if (mutate_state.locales.indexOf(payload) !== -1) {
      mutate_state.locale = payload;
    }
  },
};

export const actions = {
  setListLocales({ commit }, payload) {
    commit("SET_LOCALES", payload);
  },
  setDefaultLocale({ commit }, payload) {
    commit("SET_LOCALE", payload);
  },
};
