
import MixLocales from "@/mixins/MixLocales.js";
import mdmFooterSocial from "./mdm-footer-social.vue";
export default {
  name: "mdm-footer-politic",
  components: { mdmFooterSocial },
  mixins: [MixLocales],
  computed: {
    name() {
      return this.$store.state.name;
    },
    by() {
      return this.$store.state.by;
    },
    byLink() {
      return this.$store.state.byLink;
    },
  },
};
