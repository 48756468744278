import { render, staticRenderFns } from "./mdm-navbar.vue?vue&type=template&id=53b149e8&"
import script from "./mdm-navbar.vue?vue&type=script&lang=js&"
export * from "./mdm-navbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MdmLocales: require('D:/distrib/MDM/components/commun/mdm-locales.vue').default})
