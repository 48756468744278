export const state = () => ({
  clients: [
    {
      name: "SCP M&B INVEST MONACO",
      img: "/clients/scp-invest.svg",
      "sso-description": "desc scp-invest sso",
      description: "description scp-invest",
      "description-detail": "description scp-invest",
    },
    {
      name: "ATLAS GROUP HOLDINGS LLC <br /> Wilmington-Delaware-USA",
      img: "/clients/atlas_logo.svg",
      "sso-description":
        "desc ATLAS GROUP HOLDINGS LLC - Wilmington- Delaware- USA sso",
      description:
        "description ATLAS GROUP HOLDINGS LLC - Wilmington- Delaware- USA",
      "description-detail":
        "description ATLAS GROUP HOLDINGS LLC - Wilmington- Delaware- USA",
    },
  ],
});

export const mutations = {};

export const actions = {};
