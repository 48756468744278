
export default {
  name: "mdm-locales",
  data() {
    return {
      lang: null,
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
    locales() {
      return this.$store.state.locales.map((lang) => {
        return {
          label: this.$t(`lang.${lang}`, this.locale),
          value: lang,
        };
      });
    },
  },
  methods: {
    changedLocale(lang) {
      this.$store.dispatch("setDefaultLocale", lang);
      this.$i18n.locale = lang;
    },
  },
  created() {
    this.lang = this.locale;
  },
};
