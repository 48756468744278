
import MixLocales from "@/mixins/MixLocales.js";
export default {
  name: "mdm-contact-top",
  mixins: [MixLocales],
  computed: {
    joindres() {
      return this.$store.state.footer.joindres;
    },
  },
};
