
export default {
  name: "mdm-logo",
  props: {
    cssClass: {
      type: String,
      default: "logo",
    },
  },
};
