export const state = () => ({
  list: [
    // {type: 'link', to: "/", name: "index", title: "menu.index" },
    {
      title: "menu.about.title",
      children: [
        //{ type: "hr" },
        {
          type: "link",
          to: "/about/presentation",
          name: "about-presentation",
          title: "menu.about.pres",
        },
        { type: "link", to: "/about", name: "about", title: "menu.about.bref" },
        //{ type: "hr" },
        {
          type: "link",
          to: "/about/history",
          name: "about-history",
          title: "menu.about.histo",
        },
        //{ type: "hr" },
        {
          type: "link",
          to: "/about/values",
          name: "about-values",
          title: "menu.about.values",
        },
      ],
    },
    {
      title: "menu.strategy.title",
      children: [
        {
          type: "link",
          to: "/strategy",
          name: "strategy",
          title: "menu.strategy.strategy",
        },
        {
          type: "link",
          to: "/strategy/methodology",
          name: "strategy-methodology",
          title: "menu.strategy.methodology",
        },
      ],
    },
    // {
    //   title: "menu.equipe.title",
      // children: [
      //   {
      //     type: "link",
      //     name: "equipe",
      //     to: "/equipe",
      //     title: "menu.equipe.photo",
      //   },
      //   {
      //     type: "link",
      //     name: "equipe-profils",
      //     to: "/equipe/profils",
      //     title: "menu.equipe.profils",
      //   },
      // ],
    // },
    {
      title: "menu.investissement.title",
      children: [
        {
          type: "link",
          name: "investissement",
          to: "/investissement",
          title: "menu.investissement.appel_projet",
        },
        // {
        //   type: "link",
        //   name: "investissement-achmitech",
        //   to: "/investissement/achmitech",
        //   title: "menu.investissement.cible1",
        // },
        // {
        //   type: "link",
        //   name: "investissement-predev",
        //   to: "/investissement/predev",
        //   title: "menu.investissement.cible2",
        // },
        // {
        //   type: "link",
        //   name: "investissement-hmma",
        //   to: "/investissement/hmma",
        //   title: "menu.investissement.cible3",
        // },
      ],
    },
    {
      type: "link",
      name: "equipe",
      to: "/equipe",
      title: "menu.equipe.title",
    },
    {
      title: "menu.actualite.title",
      children: [
        {
          type: "link",
          name: "actualite",
          to: "/actualite",
          title: "menu.actualite.comminique",
        },
        // {
        //   type: "link",
        //   name: "actualite-publication",
        //   to: "/actualite/publication",
        //   title: "menu.actualite.publication",
        // },
        {
          type: "link",
          name: "actualite-participation",
          to: "/actualite/participation",
          title: "menu.actualite.participation",
        },
        // {
        //   type: "link",
        //   name: "actualite-roadshow",
        //   to: "/actualite/roadshow",
        //   title: "menu.actualite.road",
        // },
      ],
    },
    {
      type: "link",
      name: "contact",
      to: "/contact",
      title: "menu.contact.title",
    },
  ],
});

export const mutations = {};

export const actions = {};
