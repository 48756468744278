export const state = () => ({
  socialMedias: [
    // {
    //   to: "https://www.linkedin.com",
    //   title: "linkedin",
    //   icon: "linkedin",
    // },
    // { to: "https://www.facebook.com", title: "facebook", icon: "facebook" },
    // { to: "https://www.youtube.com", title: "youtube", icon: "youtube" },
    // { to: "https://www.instagram.com", title: "instagram", icon: "instagram" },
  ],
  joindres: [
    // {
    //   to: "mailto:m_amraoui@yahoo.fr",
    //   title: "Mohammed AMRAOUI",
    //   icon: "mailbox",
    //   divider: false,
    // },
    // {
    //   to: "tel:+33695005933",
    //   title: "(+33) 695 005 933",
    //   icon: "phone",
    //   divider: true,
    // },
    // {
    //   to: "mailto:m_amraoui@yahoo.fr",
    //   title: "Mohammed Benmarraze Marc",
    //   icon: "mailbox",
    //   divider: false,
    // },
    // {
    //   to: "tel:+33698962810",
    //   title: "(+33) 698 962 810",
    //   icon: "phone",
    //   divider: true,
    // },
    // {
    //   to: "#",
    //   title: "MDM CAPITAL SYNERGIES SA, 4 rue de l’ameublement 75011 PARIS",
    //   icon: "pin-map",
    // },
  ],
});

export const mutations = {};

export const actions = {};
