
import MixLocales from "@/mixins/MixLocales.js";
export default {
  name: "mdm-footer-social",
  props: {
    iconClass: {
      type: String,
      default: "",
    },
    scale: {
      type: Number,
      default: 1,
    },
  },
  mixins: [MixLocales],
  computed: {
    socialMedias() {
      return this.$store.state.footer.socialMedias;
    },
  },
};
